import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ServiceComponent } from "./service/service.component";
import { MainComponent } from "./main/main.component";
import { ValidateAdminComponent } from "./validate-admin/validate-admin.component";
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryUploadComponent } from './gallery-upload/gallery-upload.component';

import { SharedServiceService } from "./shared/shared-service.service";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    ServiceComponent,
    MainComponent,
    ValidateAdminComponent,
    GalleryComponent,
    GalleryUploadComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatCardModule,
    HttpClientModule,
  ],
  providers: [SharedServiceService],
  bootstrap: [AppComponent],
})
export class AppModule {}
