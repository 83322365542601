import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SharedServiceService } from "../shared/shared-service.service";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-gallery-upload",
  templateUrl: "./gallery-upload.component.html",
  styleUrls: ["./gallery-upload.component.css"],
})
export class GalleryUploadComponent implements OnInit {
  @ViewChild("fileInput", { static: false }) el: ElementRef;
  setting: any = {
    element: {
      dynamicDownload: null as HTMLElement,
    },
  };
  imageArray: any[] = [];
  latestImageArray: any[] = [];
  uploadBtnDisabled: boolean = true;
  imageArrayIsEmpty: boolean = true;
  position: number = 0;

  constructor(
    private router: Router,
    private _sanitizer: DomSanitizer,
    private http: HttpClient,
    private sharedServiceService: SharedServiceService
  ) {
    // if (!this.sharedServiceService.isAdmin) {
    //   this.router.navigate(["/main"]);
    // }
  }

  ngOnInit() {
    this.imageArray = [];
    this.latestImageArray = [];
    if (this.sharedServiceService.rkBoseIACSportsGallery.length === 0) {
      this.http
        .get(`/assets/json/${this.sharedServiceService.galleryJsonFileName}`)
        .subscribe((res: any) => {
          if (res && res.length > 0) {
            this.imageArray = res;
            this.sharedServiceService.rkBoseIACSportsGallery = res;
            this.loadImageData();
          }
        });
    } else {
      this.imageArray = this.sharedServiceService.rkBoseIACSportsGallery;
      this.loadImageData();
    }
  }

  loadImageData() {
    this.position = this.imageArray.length;
    this.imageArrayIsEmpty = this.imageArray.length > 0 ? false : true;
    this.imageArray.sort((a, b) => b.position - a.position);
  }

  getImagePath(imageUrl: string): any {
    return this._sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }

  uploadFile(event) {
    const target = event.target;
    if (target && target.files && target.files.length > 0) {
      const files: any[] = target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = () => {
          this.latestImageArray.push({
            position: this.position + 1,
            imageUrl: reader.result,
            classNameKey: "",
            classNameValue: "",
          });
          this.uploadBtnDisabled = false;
          this.position = this.position + 1;
        };
      }
    }
  }

  uploadSelectedImage() {
    const imageArray: any[] = this.imageArray;
    imageArray.push(...this.latestImageArray);
    this.imageArray = JSON.parse(JSON.stringify(imageArray));
    this.imageArrayIsEmpty = this.imageArray.length > 0 ? false : true;
    this.latestImageArray = [];
    this.uploadBtnDisabled = true;
  }

  downloadGalleryJson() {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement("a");
    }

    this.doSorting(this.imageArray);
    const element = this.setting.element.dynamicDownload;
    const fileType = "text/json";
    element.setAttribute(
      "href",
      `data:${fileType};charset=utf-8,${encodeURIComponent(
        JSON.stringify(this.imageArray)
      )}`
    );
    element.setAttribute(
      "download",
      this.sharedServiceService.galleryJsonFileName
    );

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

  deleteAllImage() {
    this.imageArray = [];
    this.latestImageArray = [];
    this.uploadBtnDisabled = true;
    this.imageArrayIsEmpty = true;
  }

  deleteImage(index: any) {
    this.imageArray.splice(index, 1);
    this.position = this.position - 1;
  }

  moveImageUp(position: any) {
    const imageArray: any[] = this.imageArray;
    imageArray.forEach((x, index) => {
      if (position - 1 === x.position) {
        x.position = x.position + 1;
      } else if (position === x.position) {
        x.position = x.position - 1;
      }
    });
    this.doSorting(imageArray);
  }

  moveImagedown(position: any) {
    const imageArray: any[] = this.imageArray;
    imageArray.forEach((x, index) => {
      if (position === x.position) {
        x.position = x.position + 1;
      } else if (position + 1 === x.position) {
        x.position = x.position - 1;
      }
    });
    this.doSorting(imageArray);
  }

  doSorting(imageArray) {
    imageArray.sort((a, b) => a.position - b.position);
    this.imageArray = JSON.parse(JSON.stringify(imageArray));
  }

  changeCSS(index: number) {
    let className: string = "";
    const imageArray: any[] = this.imageArray;
    let classNameKey = imageArray[index].classNameKey;

    if (!classNameKey) {
      classNameKey = "VS";
    } else if (classNameKey === "VS") {
      classNameKey = "HS";
    } else if (classNameKey === "HS") {
      classNameKey = "BS";
    } else if (classNameKey === "BS") {
      classNameKey = "";
    }

    switch (classNameKey) {
      case "VS":
        className = "v-stretch";
        break;
      case "HS":
        className = "h-stretch";
        break;
      case "BS":
        className = "big-stretch";
        break;
      default:
        className = "";
        break;
    }
    imageArray[index].classNameKey = classNameKey;
    imageArray[index].classNameValue = className;
    this.imageArray = JSON.parse(JSON.stringify(imageArray));
  }
}
