import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.css"],
})
export class AboutUsComponent implements OnInit {
  showAllSecretary = false;
  
  constructor() {}

  ngOnInit() {}

  navigateToLiveLocation() {
    window.open(
      `https://www.google.com/maps/place/RK+Bose+IAC+sports+training+center/@8.5895733,77.7044607,14.83z/data=!4m14!1m7!3m6!1s0x3b046db37e190d6d:0x508de786231bff57!2sRK+Bose+IAC+sports+training+center!8m2!3d8.5855252!4d77.7202348!16s%2Fg%2F11t_zj0xgj!3m5!1s0x3b046db37e190d6d:0x508de786231bff57!8m2!3d8.5855252!4d77.7202348!16s%2Fg%2F11t_zj0xgj`,
      "_blank"
    );
  }
}
