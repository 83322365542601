import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: "root",
})
export class SharedServiceService {
  private user = new BehaviorSubject<boolean>(false);
  rkBoseIACSportsGallery: any[] = [];
  galleryJsonFileName:String = 'rkBoseIACSportsGallery.json';
  isAdmin: boolean = false;
  $User = this.user.asObservable();

  adminUserResponse(value: boolean) {
    this.isAdmin = value;
    this.user.next(value);
  }
}
