import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { SharedServiceService } from "../shared/shared-service.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.css"],
})
export class GalleryComponent implements OnInit {
  imageArray: any[] = [];

  constructor(
    private http: HttpClient,
    private sharedServiceService: SharedServiceService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.sharedServiceService.rkBoseIACSportsGallery.length === 0) {
      this.http
        .get(`/assets/json/${this.sharedServiceService.galleryJsonFileName}`)
        .subscribe((res: any) => {
          if (res && res.length > 0) {
            this.imageArray = res;
            this.sharedServiceService.rkBoseIACSportsGallery = res;
          }
        });
    } else {
      this.imageArray = this.sharedServiceService.rkBoseIACSportsGallery;
    }
  }

  getImagePath(imageUrl: string): any {
    return this._sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }
}
