import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  imagePath: string = ``;
  defaultImagePath: string = `./assets/images/main/`;
  scrollImageCount: any[] = [1, 2, 3, 4, 5, 6];
  currentScrollImage: number = 1;
  skilledInstructor: string = `Competitive exam preparation can be quite a daunting task. RK Bose IAC Sports Training Center brings together a community of students, teachers and subject matter experts to make the process more learner friendly. We offers customized study material for all competitive exams.`;
  showAllSkilledInstructor: boolean = false;
  offlineClasses: string = `Our content and study material is available to student at no cost, We provide an open platform to the student with the best learning experience, We are investing a lot of effort into leveraging the massive amount of data that we generate in our R&D department for producing concepts and the study material to personalize the learning experience for every student with respect to all courses.`;
  showAllOfflineClasses: boolean = false;
  fieldWorkout: string = `Within track and field, sprinting, jumping, throwing, and other conditioning exercises are also a part of the overall training program. As a result, the combined energy demands and physical/emotional stress must be taken into account. In this context, planning and tracking alterations by VL can be more valuable than simply tracking changes in intensity alone.`;
  showAllFieldWorkout: boolean = false;
  accommodation: string = `We got good deals for accommodation with the reasonale price. (which includes food, electricity and water consumption). For more details, please reach out us.`;
  showAllAccommodation: boolean = false;
  
  constructor() { }

  ngOnInit() {
    this.loadScrollImage();
    setInterval(() => {
      this.moveToNextScrollImg();
    }, 5000);
  }

  moveToNextPrevImg() {
    const currentScrollImage = this.currentScrollImage - 1;
    if(currentScrollImage > 0) {
      this.currentScrollImage = currentScrollImage;
      this.loadScrollImage();
    }
  }

  moveToImg(currentScrollImage: number) {
      this.currentScrollImage = currentScrollImage;
      this.loadScrollImage();
  }

  moveToNextScrollImg() {
    const currentScrollImage = this.currentScrollImage + 1;
    if(currentScrollImage <= this.scrollImageCount.length) {
      this.currentScrollImage = currentScrollImage;    
      this.loadScrollImage();  
    } else {
      this.currentScrollImage = 1;
      this.loadScrollImage();
    }
  }

  loadScrollImage() {
    this.imagePath = `${this.defaultImagePath}${this.currentScrollImage}.jpg`;
  }

  textSubString(text: string, length: number) {
    return text.substring(0, length);
  }

}
