import { Component, OnInit, OnDestroy } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, OnDestroy {
  imageSource: string = `./assets/images/home/auto-scroll/1.jpg`;
  totalImageCount: number = 2;
  currentImageCount: number = 1;
  url: string = "./assets/doc/home.pdf";
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.currentImageCount = 1;
    this.loadDymanicImage();
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  loadDymanicImage() {
    // setInterval(() => {
    //   this.currentImageCount =
    //     this.currentImageCount === this.totalImageCount
    //       ? 1
    //       : ++this.currentImageCount;
    //   this.imageSource = `./assets/images/home/auto-scroll/${this.currentImageCount}.jpg`;
    // }, 3000);
  }

  ngOnDestroy() {
    // clearInterval();
  }
}
