import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ServiceComponent } from "./service/service.component";
import { MainComponent } from './main/main.component';
import { ValidateAdminComponent } from "./validate-admin/validate-admin.component";
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryUploadComponent } from './gallery-upload/gallery-upload.component';

const routes: Routes = [
  { path: "main", component: MainComponent },
  { path: "home", component: HomeComponent },
  { path: "services", component: ServiceComponent },
  { path: "gallery", component: GalleryComponent },
  { path: "gallery-upload", component: GalleryUploadComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "login", component: ValidateAdminComponent },
  { path: "", redirectTo: "main", pathMatch: "full" },
  { path: "**", redirectTo: "main" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
