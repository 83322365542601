import { Component, OnInit } from "@angular/core";
import { SharedServiceService } from "../shared/shared-service.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-validate-admin",
  templateUrl: "./validate-admin.component.html",
  styleUrls: ["./validate-admin.component.css"],
})
export class ValidateAdminComponent implements OnInit {
  userName: string = "";
  password: string = "";
  inValidUser: boolean = false;

  constructor(
    private router: Router,
    private sharedServiceService: SharedServiceService
  ) {}

  ngOnInit() {}

  resetErrorMessage() {
    this.inValidUser = false;
  }

  validateAdminUser() {
    this.resetErrorMessage();
    const btoaUserName = btoa(this.userName);
    const btoaPassword = btoa(this.password);
    if (
      btoaUserName === `cmtCb3NlQWRtaW4=` &&
      btoaPassword === `a2tya0Jvc2VAMDMwOTIwMjA=`
    ) {
      this.inValidUser = false;
      this.sharedServiceService.adminUserResponse(true);
      this.router.navigate(["/main"]);
    } else {
      this.inValidUser = true;
    }
  }
}
