import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SharedServiceService } from "./shared/shared-service.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "rkboseIAC";
  isAdmin: boolean = false;

  constructor(
    private router: Router,
    private sharedServiceService: SharedServiceService
  ) {}

  ngOnInit() {
    this.sharedServiceService.$User.subscribe((user) => {
      console.log("isAdmin - ", user);
      this.isAdmin = user;
    });
  }

  openNav() {
    document.getElementById("responsive-side-nav").style.width = "220px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }

  closeNav() {
    document.getElementById("responsive-side-nav").style.width = "0";
    document.body.style.backgroundColor = "white";
  }

  routeToNextPage(path: string) {
    this.router.navigate([path]);
    this.closeNav();
  }
}
